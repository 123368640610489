export function form() {
	$('form').on('submit', function(e) {
		e.preventDefault();

		var $form = $(this);

		if ( $form.find("[name=phone]").length > 0 ) {
			if ( $form.find("[name=phone]").val().indexOf("_") > 0 ) {
				$form.find("[name=phone]").css("border", "1px solid #A00000");
				$form.find('[name=phone]').siblings('span').addClass('disable');
				$form.find("p.phone-error").text("Телефон введен не полностью").fadeIn(0);
				e.stopPropagation();
				return false;
			} else {
				$form.find("[name=phone]").css("border", "1px solid #AFAFAF");
				$form.find('[name=phone]').siblings('span').removeClass('disable');
				$form.find("p.phone-error").text("").fadeOut(0);
			}
		}
		
		$.ajax({
			type: 'POST',
			dataType: 'json',
			url: '/templates/theme306/new-assets/php/contact.php',
			data: $form.serialize(),
			success: function (data) {
				if ( data.success ) {
					$('.modal').modal('hide');
					$('#modal-success').modal('show');
				}
			},
			complete: function (data) {
				if ( JSON.parse(data.responseText).success ) {
					$form.find('input[type="text"]').css("border", "1px solid #AFAFAF");
					$form.find('input[type="text"]').val('');
				}
				setTimeout(function() {
					$('.modal').modal('hide');
				}, 10000);
			},
			error: function (a, b, c) {}
		});
	})
}