import {form} from './components/form.js';

{
	var width 	= $(window).innerWidth();
	var height 	= $(window).innerHeight();

	// init functions
	form();

	// preloader
	setTimeout(function() {
		$('#preloader').fadeOut(500);
	}, 500);
	// end preloader

	// segment preview choose
	$('.preview-item-content__segment-choose').find('a[data-toggle="segment-choose"]').on('click', function(e) {
		e.preventDefault();
		var elem 	= $(this);
		var target	= $('.segments-list__item').eq( elem.data('target').split('-')[1] - 1 );
		var title 	= target.find('h3').text().toLowerCase();
		
		if ( elem.data('target') != 'segment-3' ) {
			$('#features').find('.section-title .title').html(`Преимущества использования котлов "СТАРТ" <span>${title}</span>`);
		} else {
			$('#features').find('.section-title .title').html(`Преимущества выбора котлов "СТАРТ" <span>${title}</span>`);
		}

		$('.segments-list__item').removeClass('segments-list__item_current');
		target.addClass('segments-list__item_current');

		$('#callback-float .callback-float__text h2').text(target.data('callback-float-title'));
		$('#callback-float .callback-float__text p').text(target.data('callback-float-text'));
		$('#callback-float .callback-float__action a').attr('data-target', target.data('callback-float-modal'));

		$('html, body').animate({ scrollTop: $('.segments-list').offset().top - 65 }, 1000);
	});
	// end segment preview choose

	// carousel
	var $previewCarousel = $('.preview-list').owlCarousel({
		items: 1,
		loop: false,
		dots: true,
		nav: true,
		navText: ["<span><i class='fas fa-angle-left'></i></span>", "<span><i class='fas fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false,
		autoheight: width < 768
	});

	var $faqCarousel = $('.faq-image-list').owlCarousel({
		items: 1,
		loop: false,
		dots: false,
		nav: true,
		navText: ["<span><i class='fas fa-angle-left'></i></span>", "<span><i class='fas fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false
	});

	var $reviewsCarousel = $('.reviews-list').owlCarousel({
		items: 3,
		loop: false,
		dots: 1,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false,
		responsive: {
	        0: 		{ items: 1, },
	        600: 	{ items: 2, },
	        1000: 	{ items: 3 }
	    }
	});

	var $newsCarousel = $('.news-list').owlCarousel({
		items: 3,
		loop: false,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false,
		responsive: {
	        0: 		{ items: 1, },
	        600: 	{ items: 2, },
	        1000: 	{ items: 3 }
	    }
	});
	// end carousel

	// segments + features controller
	var $featuresSection 	= $('#features');
	var $featuresList 		= $('.features-list');
	$('.segments-list__item').on('click', function(e) {
		e.preventDefault();
		var elem 	= $(this);
		var index 	= elem.index();
		var title 	= elem.find('h3').text().toLowerCase();

		elem.closest('.segments-list').find('.segments-list__item').removeClass('segments-list__item_current');
		elem.addClass('segments-list__item_current');

		if ( index != 2 ) {
			$featuresSection.find('.section-title .title').html(`Преимущества использования котлов "СТАРТ" <span>${title}</span>`);
		} else {
			$featuresSection.find('.section-title .title').html(`Преимущества выбора котлов "СТАРТ" <span>${title}</span>`);
		}

		$featuresList.removeClass('features-list_show');
		$featuresList.eq(index).addClass('features-list_show');
		
		$('#callback-float .callback-float__text h2').text(elem.data('callback-float-title'));
		$('#callback-float .callback-float__text p').text(elem.data('callback-float-text'));
		$('#callback-float .callback-float__action a').attr('data-target', elem.data('callback-float-modal'));

		setTimeout(() => {
			$('html, body').stop()
			.animate({ scrollTop: $('#features').offset().top - 63 }, 1000);
		}, 500);
	});
	// end segments + features controller

	// chart
	if ( $('#chart').length ) {
		var chartChart1 = new CanvasJS.Chart("chart",
		{
			theme: "theme0",
			backgroundColor: "rgba(0,0,0,0)",
			colorSet: "redShades",
			animationEnabled: true,
			axisX: {
				margin: 0,
				lineColor: "#333333",
				labelFontColor: "#000000",
				interval: 2,
				includeZero: false,
				gridThickness: 0.1,
				gridColor: "rgba(0,0,0,0.20)"

			},
			axisY: {
				margin: 0,
				includeZero: false,
				lineColor: "#333333",
				labelFontColor: "#000000",
				interval: 50,
				gridThickness: 0.1,
				gridColor: "rgba(0,0,0,0.20)"
			},
			toolTip:{  
				fontStyle: "normal"
			},
			data: [
			{        
				type: "column",
				dataPoints: [
				{ x: 1, y: 80 },
				{ x: 2, y: 50},
				{ x: 3, y: 100 },
				{ x: 4, y: 150 },
				{ x: 5, y: 190 },
				{ x: 6, y: 80},
				{ x: 7, y: 160 },
				{ x: 8, y: 120 },
				{ x: 9, y: 190 },
				{ x: 10, y: 80},
				{ x: 11, y: 120 },
				{ x: 12, y: 150 }
				]
			}
			]
		});

		chartChart1.render();
		chartChart1 = {};
	}

	// end chart

	// faq list
	// var $faqList = $('.faq-list');
	// if ( $faqList.length ) {
	// 	$faqList.find('.faq-item__title').on('click', function(e) {
	// 		e.preventDefault();
	// 		var elem = $(this);
	// 		var wrap = elem.closest('.faq-item');	

	// 		wrap.addClass('faq-item_active');
	// 		wrap.find('.faq-item__content').slideDown(500);

	// 		$faqList.find('.faq-item').not(wrap).removeClass('faq-item_active');
	// 		$faqList.find('.faq-item').not(wrap).find('.faq-item__content').slideUp(500);
	// 	});
	// }
	// end faq list

	// lightgallery
	$('.lightgallery').lightGallery({
		thumbnail: true,
		selector: 'a',
		download: false
	});

	$('.lightgallery-item').lightGallery({
		thumbnail: true,
		selector: 'this',
		download: false
	});
	// end lightgallery

	// masonry gallery
	var $works = $('.works-list').isotope({
		itemSelector: '.works-item'
	});

	$('.works-menu').find('a').on('click', function(e) {
		e.preventDefault();
		var elem 	= $(this);
		var wrap 	= elem.parent();
		var filter	= elem.data('filter');

		$('.works-menu').find('li').removeClass('active');
		wrap.addClass('active');
		$works.isotope({ filter: filter });
	});
	// end masonry gallery

	// gmap
	// var latlng = new google.maps.LatLng(55.192550, 61.370855);
	// var myOptions = {
	// 	zoom: 17,
	// 	center: latlng,
	// 	mapTypeId: google.maps.MapTypeId.ROADMAP
	// };
	// var map = new google.maps.Map(document.getElementById('gmap'), myOptions);
	// var marker = new google.maps.Marker({
	// 	position: latlng,
	// 	map: map,
	// 	title: '"Дружный"'
	// });
	// end gmap

	// lightgallery
	$('.lightgallery-item').lightGallery({
		thumbnail: true,
		selector: 'this',
		download: false
	});
	// end lightgallery

	// input mask
	$('.inputmask-phone').inputmask("mask", {
		"mask": "+7 (999) 999-99-99",
		showMaskOnHover: false
	});

	// scroll sticky
	
	$(window).on('load scroll', function() {
		var elem = $(this);
		if ( elem.scrollTop() > $('.main-header').height() ) {
			$('.main-header').addClass('fixed-header');
			$('.scroll-to-top').fadeIn(300);
		} else {
			$('.main-header').removeClass('fixed-header');
			$('.scroll-to-top').fadeOut(300);
		}
	})

	var scrollOffset = 61;
	if ( width <= 768 ) { scrollOffset = 0; }

	$('.scroll').bind('click', function(event) {
		var elem 	= $(this);
		var anchor 	=  $( elem.attr('href') );

		$('html, body').stop()
		.animate({
			scrollTop: anchor
			.offset()
			.top - scrollOffset }, 1200);
		event.preventDefault();
	});
}